@import 'Styles/commonVariables.sass'
@import 'Styles/utils.sass'

.button
  appearance: none
  outline: none
  border: 0
  border-radius: 0
  box-shadow: none
  &:hover,
  &:focus,
  &:active
    outline: none
    border: 0
    border-radius: 0
    box-shadow: none

.main
  display: block

// *****
// ХЭДЕР
// *****
.branchPageHeaderContainer
  display: block
  width: 100%
  height: auto
  border-bottom: 1px solid $text-field-and-rulers

.branchPageHeaderFirstLine
  display: flex
  flex-direction: row
  flex-wrap: nowrap
  flex-flow: row nowrap
  align-items: flex-start
  align-content: flex-start
  justify-content: space-between
  width: 100%
  max-width: 1280px
  margin: 0 auto
  padding: 13px 76px 14px
  +breakpointMinMax(768, 1023)
    padding: 13px 15px 14px
  +breakpoint(767)
    display: block
    padding: 13px 15px 14px

.branchPageHeaderSecondLine
  display: flex
  flex-direction: row
  flex-wrap: nowrap
  flex-flow: row nowrap
  align-items: flex-start
  align-content: flex-start
  justify-content: space-between
  width: 100%
  max-width: 1280px
  margin: 0 auto
  padding: 0px 76px 18px
  +breakpointMinMax(768, 1023)
    padding: 0px 15px 18px
  +breakpoint(767)
    display: block
    padding: 0px 15px 18px

.branchHeaderLeftPart
  display: flex
  flex-grow: 1
  max-width: 840px
  padding: 0
  +breakpoint(767)
    display: block

.branchHeaderRightPart
  display: flex
  +breakpointMinMax(768, 1023)
    flex-direction: column
  +breakpoint(767)
    display: flex
    flex-wrap: wrap

.branchLogo
  display: block
  width: 62px
  height: 62px
  margin: 0 17px 0 0
  border-radius: 8px
  overflow: hidden
  border: 1px solid $text-field-and-rulers
  & img
    height: 100%
    max-height: 100%
    width: 100%
    max-width: 100%
    object-fit: contain
  +breakpoint(767)
    margin: 0 0 12px

.branchHeadingInfo
  display: block

.branchNameWrap
  display: block

.branchName
  display: inline-block
  width: auto
  max-width: 548px
  margin: 0
  padding: 0 5px 0 0
  font-size: 24px
  font-weight: 500
  line-height: 36px
  overflow-wrap: break-word
  color: $neutral-deep-black
  text-align: left
  +breakpoint(767)
    max-width: 100%

.updateBranchInfoBtn
  appearance: none
  display: flex
  align-items: center
  gap: 5px
  margin: 0 0 0 12px
  padding: 0
  font-size: 14px
  font-weight: 400
  line-height: 20px
  color: $neutral-dark-gray
  text-align: left
  position: relative
  outline: none
  border: 0
  border-radius: 0
  box-shadow: none
  +breakpoint(767)
    margin: 16px 0 0 0
  svg
    width: 12px
    height: 12px
    opacity: 0.55
  &:hover,
  &:focus,
  &:active
    outline: none
    border: 0
    border-radius: 0
    box-shadow: none
    text-decoration: none

// ИНФОРМАЦИЯ О ФИЛИАЛЕ
.branchHeaderInfo
  display: flex
  flex-direction: row
  flex-wrap: nowrap
  flex-flow: row nowrap
  align-items: center
  align-content: flex-start
  justify-content: space-between
  margin: 0
  +breakpointMinMax(768, 1023)
    display: block
    margin: 6px 0 0
  +breakpoint(767)
    display: block
    margin: 4px 0 6px

.branchHeaderInfo__address
  display: block
  width: auto
  font-size: 14px
  line-height: 20px
  font-weight: normal
  color: $neutral-deep-black
  margin-right: 12px
  max-width: 400px
  +breakpointMinMax(768, 1023)
    margin: 0 0 3px

.branchHeaderInfo__createdAt
  font-size: 14px
  line-height: 20px
  color: $neutral-dark-gray
  margin-top: 4px

.branchHeaderInfo__badgeWrap
  margin-top: 5px
  margin-right: 8px

.branchDivisionsContainer
  display: flex
  align-items: center
  justify-content: flex-end
  flex-shrink: 0
  margin: 10px 0 0
  +breakpointMinMax(768, 1023)
    width: auto
  +breakpoint(767)
    justify-content: flex-start
    flex-wrap: wrap
    width: 100%

.branchDivisions
  +breakpoint(767)
    width: 100%

.branchNumberContainer
  margin: 10px 0 0
  display: flex
  justify-content: flex-end
  align-items: center
  +breakpoint(767)
    justify-content: flex-start
  +breakpoint(375)
    width: 100%

.branchNumber
  border: 1px solid #e0e9e5
  padding: 9px 12px
  border-radius: 8px
  margin-left: 12px
  width: 100px
  display: flex
  &_disabled
    background: $background-disable
    border: 1px solid #edf1f3
  span
    color: $neutral-dark-gray
  input
    border: none
    width: 100%
  +breakpoint(767)
    max-width: 90px
    margin-left: 0
    margin-right: 15px
  +breakpoint(375)
    max-width: inherit
    width: 100%
    margin-right: 0

.branchWorkingStatusContainer
  display: flex
  align-items: center
  justify-content: flex-end
  flex-shrink: 0
  flex-grow: 1
  margin: 10px 0 0 14px
  +breakpointMinMax(768, 1023)
    width: auto
  +breakpoint(767)
    margin: 10px 0 0 0
    justify-content: flex-start

// *******
// КОНТЕНТ
// *******
.branchPageContentWrapper
  display: flex
  flex-direction: row
  flex-wrap: nowrap
  flex-flow: row nowrap
  align-items: flex-start
  align-content: flex-start
  justify-content: space-between
  width: 100%
  max-width: 1280px
  margin: 0 auto
  padding: 25px 75px
  +breakpoint(1023)
    padding: 25px 30px
  +breakpoint(767)
    display: block
    padding: 15px
.branchPageContent
  display: block
  width: 100%
  max-width: 840px
  flex-grow: 1
  flex-shrink: 1
  margin: 0 72px 0 0
  +breakpointMinMax(1024, 1199)
    margin: 0 32px 0 0
  +breakpoint(1023)
    margin: 0

.branchPageSection
  display: block
  padding: 0 0 30px
  &_rubrics
    margin-bottom: -12px // Поменять на 0

.branchPageSectionCards
  display: block
  padding: 0 0 10px

.branchPageH2
  display: block
  margin: 0 0 13px
  padding: 0
  font-size: 22px
  font-weight: 500
  line-height: 28px
  letter-spacing: -0.27px
  color: $neutral-deep-black
  & .correctnessDate
    color: #87938f
.branchPageH2Photos
  composes: branchPageH2
  margin: 0 0 10px

.borderFlex
  display: flex
  flex-direction: row
  flex-wrap: nowrap
  flex-flow: row nowrap
  align-items: stretch
  align-content: stretch
  justify-content: flex-start
  border: 1px solid #ebf0ee
  background-color: #fff
  box-shadow: 0 2px 4px 0 $neutral-lightest-gray
  border-radius: 8px
  +breakpoint(567)
    display: block

.servicesFlex
  display: flex
  flex-direction: row
  flex-wrap: wrap
  flex-flow: row wrap
  align-items: stretch
  align-content: stretch
  justify-content: flex-start
  width: 100%
  height: auto

.serviceFlexCard
  display: block
  width: calc(100% / 3)
  flex-basis: calc(100% / 3)
  margin: 0
  padding: 16px 16px 18px
  border-right: 1px solid $text-field-and-rulers
  &:last-child
    border-right: 0
  +breakpoint(567)
    width: 100%
    flex-basis: 100%
    padding: 14px 12px
    border-right: 0
    border-bottom: 1px solid $text-field-and-rulers
    &:last-child
      border-right: 0
      border-bottom: 0

.serviceFlexCardPubStatus
  composes: serviceFlexCard
  min-height: 96px

.servicesFlexCard
  display: block
  width: calc(100% / 3 - 16px)
  flex-basis: calc(100% / 3 - 16px)
  margin: 0 24px 24px 0
  padding: 16px 16px 18px
  border: 1px solid $text-field-and-rulers
  border-radius: 12px
  background-color: #fff
  box-shadow: 0 2px 4px 0 $neutral-lightest-gray
  &:nth-child(3n)
    margin-right: 0
  +breakpointMinMax(414, 650)
    width: calc(50% - 8px)
    flex-basis: calc(50% - 8px)
    margin: 0 16px 16px 0
    padding: 14px 12px
    &:nth-child(3n)
      margin-right: 16px
    &:nth-child(2n)
      margin-right: 0
  +breakpoint(413)
    width: 100%
    flex-basis: 100%
    padding: 14px 12px
    margin: 0 0 16px
    &:last-child
      margin: 0

.servicesFlexCardPubStatus
  composes: servicesFlexCard
  min-height: 96px
  &__checkResultInfo
    padding-top: 10px
  &__pubInfo
    min-height: 68px
    margin-bottom: 10px
    +breakpoint(767)
      min-height: initial
  &__separateTop
    border-top: 1px solid $text-field-and-rulers

.serviceTitle
  display: block
  width: auto
  margin: 0 0 8px
  padding: 0
  font-size: 12px
  font-weight: 500
  line-height: 16px
  letter-spacing: 1px
  text-transform: uppercase
  color: $neutral-dark-gray

.serviceTitleCorrectness
  composes: serviceTitle
  font-size: 12px
  line-height: 16px

.serviceTitleLink
  composes: serviceTitle
  & a
    color: #171d1a
    cursor: pointer
    transition: color .27s ease
    text-decoration: none
    position: relative
    display: flex
    align-items: center
    gap: 4px
    svg
      path
        transition: fill .27s ease
        fill: $brand-green-default
    &:hover
      color: $brand-purple-default
      text-decoration: none
      svg path
        fill: $brand-purple-default

// рейтинг
.branchRating
  display: block
  min-height: 44px
  margin: 0 0 1px

.branchRating__value
  display: block
  margin: 0 0 8px
  padding: 0
  font-size: 22px
  font-weight: 500
  line-height: 24px
  color: $neutral-deep-black
  text-align: left

.serviceData
  display: block

.serviceDataRates,
.serviceDataFeedback
  font-size: 10px
  font-weight: normal
  line-height: 20px
  color: $neutral-dark-gray

.serviceDataFeedback
  &::before
    content: ''
    display: inline-block
    width: 2px
    height: 2px
    margin: 0 4px 0 5px
    vertical-align: middle
    background-color: $neutral-dark-gray
    border-radius: 50%

.serviceDataNull
  font-size: 14px
  font-weight: normal
  line-height: 20px
  color: $neutral-dark-gray

// ****************
// НАВИГАЦИЯ СПРАВА
// ****************
.branchPageAsideNav,
.sticky > div
  display: block
  width: 216px
  height: auto
  border-left: 1px solid #e0e9e5
  overflow: hidden
  +breakpoint(1023)
    display: none

.branchPageAsideNav
  border: 0

.branchPageAsideNav__link
  composes: button
  display: block
  width: 100%
  height: auto
  min-height: 40px
  margin: 0
  padding: 9px 8px 11px 23px
  background-color: transparent
  font-size: 14px
  font-weight: 500
  line-height: 20px
  color: $neutral-deep-black
  text-align: left
  position: relative
  cursor: pointer
  +breakpointMinMax(1024, 1199)
    padding: 9px 0 11px 20px
  &::before
    content: ''
    display: block
    width: 6px
    height: 100%
    max-height: 0
    border-radius: 6px
    background-color: $brand-purple-default
    position: absolute
    top: 51%
    bottom: 51%
    left: -3px
    transition: color .27s ease
  &:hover
    color: $brand-purple-default
    text-decoration: none
    &::before
      max-height: 100%
      top: 0
      bottom: 0

.branchPageAsideNav__link_active
  composes: branchPageAsideNav__link
  color: $brand-purple-default
  &::before
    max-height: 100%
    top: 0
    bottom: 0

.pubStatus
  display: block
  margin: 0 0 4px
  padding: 0
  font-size: 14px
  line-height: 20px
  color: $neutral-dark-gray

.pubStatusYes
  composes: pubStatus
  color: $neutral-deep-black

.pubStatusGoogleGreen
  composes: pubStatus
  color: $neutral-deep-black
  &::before
    content: ''
    display: inline-block
    width: 8px
    height: 8px
    margin: 0 4px 0 0
    border-radius: 50%
    background-color: #25ad71
    vertical-align: unset

.pubStatusGoogleYellow
  composes: pubStatus
  color: $neutral-deep-black
  &::before
    content: ''
    display: inline-block
    width: 8px
    height: 8px
    margin: 0 4px 0 0
    border-radius: 50%
    background-color: #fd9d49
    vertical-align: unset

.pubStatusGoogleRed
  composes: pubStatus
  color: $neutral-deep-black
  &::before
    content: ''
    display: inline-block
    width: 8px
    height: 8px
    margin: 0 4px 0 0
    border-radius: 50%
    background-color: #e03a3a
    vertical-align: unset

.pubConfirmed
  display: flex
  align-items: center
  gap: 3px
  width: auto
  margin: 0
  padding: 0
  font-size: 14px
  line-height: 20px
  color: $neutral-deep-black
  position: relative
  svg
    path
      fill: $status-info-default

// Оcобенности филиала
.buttonWithIconAndDesc
  composes: button
  border-radius: 8px
  transition: background-color .27s ease, color .27s ease
  text-decoration: none
  height: 40px
  display: flex
  align-items: center
  padding: 0 16px 0 12px
  cursor: pointer
  &__textWrap
    +breakpoint(767)
      flex-wrap: wrap
      display: flex
  &__label
    font-weight: bold
    font-size: 12px
    letter-spacing: 1px
    text-transform: uppercase
    white-space: nowrap
  &__desc
    display: inline-block
    margin: 0 0 0 4px
    font-weight: normal
    font-size: 12px
    white-space: nowrap
    +breakpoint(767)
      margin: 0
  & svg
    margin: 0 7px 0 0
    & *
      transition: stroke .27s ease
  &:focus,
  &:active
    border-radius: 8px
  &:hover
    border-radius: 8px
    background: $brand-purple-lightest
    color: $brand-purple-default
    text-decoration: none
    & svg *
      stroke: $brand-purple-default
  +breakpoint(359)
    padding: 0 10px
    font-size: 11px
  &_green
    color: $brand-green-default
    background: $brand-green-lightest
    & svg
      & *
        stroke: #2AC682
  &_blue
    color: $status-info-default
    background-color: $status-info-default
    & svg
      & *
        stroke: $status-info-default

// Прайс
.price
  &__items
    display: flex
    flex-wrap: wrap
    justify-content: flex-start
    width: 100%
    height: auto
  &__item
    min-height: 96px
    width: calc(100% / 3 - 16px)
    flex-basis: calc(100% / 3 - 16px)
    margin: 0 24px 0 0
    padding: 15px
    border: 1px solid $text-field-and-rulers
    border-radius: 12px
    box-shadow: 0px 2px 4px rgba(92, 106, 117, 0.06)
    &:nth-child(3n)
      margin-right: 0
    +breakpointMinMax(414, 650)
      width: calc(50% - 8px)
      flex-basis: calc(50% - 8px)
      margin: 0 16px 16px 0
      padding: 14px 12px
      &:nth-child(3n)
        margin-right: 16px
      &:nth-child(2n)
        margin-right: 0
    +breakpoint(413)
      width: 100%
      flex-basis: 100%
      padding: 14px 12px
      margin: 0 0 16px
      &:last-child
        margin: 0

  &__name
    font-size: 12px
    font-weight: 500
    line-height: 16px
    letter-spacing: 1px
    text-transform: uppercase
    color: $neutral-dark-gray
    padding-bottom: 6px
  &__status
    &__name
      font-weight: 500
      font-size: 14px
      line-height: 20px
      padding-bottom: 3px
      &_green
        color: $brand-green-default
      &_blue
        color: #3386E1
      +breakpoint(374)
        font-size: 12px
    &__icon
      height: 22px
      &_green
        svg path
          stroke: $brand-green-default
        svg rect
          fill: $brand-green-default
      &_blue
        svg *
          fill: #3386E1

// Проверка корректности данных
.dataCorrectnessFlex
  display: flex
  flex-direction: row
  flex-wrap: nowrap
  flex-flow: row nowrap
  align-items: flex-end
  align-content: flex-end
  justify-content: flex-start
  width: 100%
  height: auto

.dataCorrectnessFlexData
  display: block
  width: 76px
  flex-basis: 76px
  margin: 0 12px 0 0

.dataCorrectnessFlexPercentage
  display: block
  font-size: 22px
  font-weight: 600
  line-height: 24px
  color: $neutral-deep-black

.dataCorrectnessFlexGraph
  display: block
  width: auto
  height: 40px
  max-width: 156px
  flex-grow: 1
  flex-shrink: 0

.dataCorrectnessFlexGraphEmpty
  composes: dataCorrectnessFlexGraph
  border-bottom: 2px solid #c9d3ce

// ВИДЫ ДЕЯТЕЛЬНОСТИ
.specializationBlock
  display: block
  width: 100%
  height: auto
  margin: 0 0 12px
  padding: 16px
  border: 1px solid #ebf0ee
  background-color: #fff
  box-shadow: 0 2px 4px 0 $neutral-lightest-gray
  border-radius: 12px
  position: relative
  &__leftColorLine
    display: block
    content: ''
    height: 85%
    width: 2px
    position: absolute
    left: 0
    top: calc(15% / 2)
    border-radius: 0 5px 5px 0
    &_yandex
      background: #EABE10
    &_google
      background: #EA4335
    &_twogis
      background: #30AD00

.specializationBlockTitle
  display: block
  margin: 0 0 14px
  padding: 0
  font-size: 18px
  font-weight: 500
  line-height: 24px
  color: $neutral-deep-black

.specializationTags
  display: flex
  flex-direction: row
  flex-wrap: wrap
  flex-flow: row wrap
  align-items: flex-start
  align-content: flex-start
  justify-content: flex-start
  width: 100%
  height: auto

.featureButtonsWrap
  margin-top: 8px

// **********
// ФОТОГРАФИИ
// **********
.photosSubtitle
  display: flex
  flex-direction: row
  flex-wrap: wrap
  flex-flow: row wrap
  align-items: flex-start
  align-content: flex-start
  justify-content: space-between
  width: 100%
  margin: 5px 0 7px
  color: $neutral-dark-gray

.photosSubtitleText
  display: block
  font-size: 14px
  line-height: 20px
  font-weight: 400
  color: #646a68
  text-align: left

.photosSubtitleNotification
  display: block
  font-size: 14px
  line-height: 20px
  font-weight: normal
  color: $status-danger-default
  text-align: right

// BRANCH DATA SHEET
.branchDataWrapper
  display: block
  background: #fff
  border: 1px solid $text-field-and-rulers
  box-shadow: 0px 2px 4px rgba(92, 106, 117, 0.06)
  border-radius: 12px

.branchDataSection
  display: block
  padding: 16px 16px
  &_p0
    padding: 0

.branchDataSectionHeading
  display: block
  margin: 0 0 16px
  padding: 0
  font-size: 14px
  font-weight: 600
  line-height: 20px
  color: $neutral-deep-black

.branchDataGroup
  margin: 0 0 17px

.branchDataFlexRow
  display: flex
  flex-direction: row
  flex-wrap: wrap
  flex-flow: row wrap
  align-items: center
  align-content: flex-start
  justify-content: flex-start
  width: 100%
  +breakpoint(767)
    display: block
.branchDataFlexRowUp
  composes: branchDataFlexRow
  align-items: flex-start
  align-content: flex-start
.branchDataBlockRow
  display: block
  width: 100%
  position: relative

.branchDataInputContainer
  position: relative
  display: block
  width: 60%
  flex-basis: 60%
  +breakpoint(767)
    width: 100%
    max-width: 300px
.branchDataInputContainer_fullWidth
  composes: branchDataInputContainer
  +breakpoint(767)
    width: 100%
    max-width: inherit
.branchDataInputContainer_addr
  composes: branchDataInputContainer
  width: 100%

.branchDataLabel
  display: block
  width: 100%
  max-width: 392px
  margin: 0
  font-size: 14px
  font-weight: 400
  line-height: 20px
  color: $neutral-deep-black
  position: relative

.branchDataLabelError
  composes: branchDataLabel
  color: $status-danger-default

.branchDataPseudoLabel
  display: block
  width: 100%
  max-width: 220px
  margin: 0 0 7px
  font-size: 14px
  font-weight: 400
  line-height: 20px
  color: $neutral-deep-black
  position: relative
  word-break: break-word
  +breakpoint(1124)
    max-width: 380px

.branchDataInputText
  appearance: none
  box-shadow: none
  display: block
  width: 100%
  max-width: 392px
  height: 40px
  margin: 7px 0 0
  padding: 0 16px
  border-radius: 8px
  border: 1px solid $text-field-and-rulers
  font-size: 14px
  font-weight: 400
  line-height: 20px
  color: $neutral-deep-black
  &:hover
    border-color: $brand-green-default
.branchDataLabelError .branchDataInputText
  background-color: $status-danger-lightest
  border-color: $status-danger-default
.branchDataInputTextRO
  composes: branchDataInputText
  border: 1px solid #f0f4f2
  background-color: #f0f4f2
  pointer-events: none
  &:hover
    border-color: #f0f4f2
.branchDataInputText__error
  border-color: $status-danger-default

.branchDataInputError
  display: block
  font-size: 12px
  font-weight: 400
  line-height: 14px
  color: $status-danger-default
  padding: 5px 10px 5px

.branchROvalue
  display: block
  width: 100%
  max-width: 392px
  height: auto
  min-height: 40px
  margin: 7px 0 0
  padding: 10px 16px
  border-radius: 8px
  border: 1px solid #f0f4f2
  background-color: #f0f4f2
  font-size: 14px
  font-weight: 400
  line-height: 20px
  color: $neutral-deep-black

.branchDataTextarea
  display: block
  width: 100%
  max-width: 392px
  height: 80px
  margin: 2px 0 0 0
  padding: 9px 16px
  border-radius: 8px
  border: 1px solid $text-field-and-rulers
  font-size: 14px
  font-weight: 400
  line-height: 20px
  color: $neutral-deep-black
  transition: border-color .27s ease
  resize: none
  &:hover
    border-color: $brand-purple-default

.branchDataTextareaRO
  composes: branchDataTextarea
  border: 1px solid #f0f4f2
  background-color: #f0f4f2
  pointer-events: none
  &:hover
    border-color: #f0f4f2

.textareaWithCounterWrapper
  padding-right: 24px
  +breakpoint(767)
    padding-right: 0px

.textareaWithCounter
  display: block
  width: 100%
  position: relative
  &_big
    composes: branchDataTextarea
    max-width: inherit
    padding: 9px 16px 30px
    overflow: hidden
  &_bigRO
    composes: branchDataTextarea
    overflow: hidden
    padding: 9px 16px 30px
    height: auto
    max-width: inherit
    padding: 9px 16px
    border: 1px solid #f0f4f2
    background-color: #f0f4f2
    pointer-events: none
    &:hover
      border-color: #f0f4f2
  &_error
    border-color: $status-danger-default
  &__counter
    display: block
    width: 26px
    height: 16px
    font-size: 12px
    font-weight: 400
    line-height: 16px
    color: $neutral-dark-gray
    text-align: right
    position: absolute
    bottom: 12px
    right: 40px
    z-index: 1

// hint
.branchDataHint
  display: block
  max-width: 264px
  font-size: 12px
  font-weight: normal
  line-height: 16px
  color: $neutral-dark-gray
  margin-top: 12px
  +breakpoint(1125)
    display: none
.branchDataHint_addr
  composes: branchDataHint
  display: inline-block
  width: auto
  max-width: none
  margin: 0
  position: absolute
  right: 0
  top: 0
  z-index: 1
  text-align: right
  +breakpoint(1125)
    display: none

.branchDataHint_fullWidth
  composes: branchDataHint
  +breakpoint(1125)
    display: block
  +breakpoint(767)
    display: block
    max-width: inherit

// complex input
.complexInputBlock
  display: flex
  flex-direction: row
  flex-wrap: nowrap
  flex-flow: row nowrap
  align-items: center
  align-content: center
  justify-content: space-between
  width: 100%
  max-width: 392px
  height: 40px
  margin: 7px 0 0
  padding: 0 8px 0 16px
  border-radius: 8px
  border: 1px solid $text-field-and-rulers
  font-size: 14px
  font-weight: 400
  line-height: 20px
  color: $neutral-deep-black
  transition: border-color .27s ease
  position: relative
  &:hover
    border-color: $brand-purple-default

.complexInputBlockRO
  composes: complexInputBlock
  border: 1px solid #f0f4f2
  background-color: #f0f4f2
  pointer-events: none
  &:hover
    border-color: #f0f4f2

.complexInputBlock__error
  border-color: $status-danger-default

.complexInputBlock__input
  display: block
  width: calc(100% - 24px)
  margin: 0
  padding: 0
  border: 0
  background-color: transparent
  font-size: 14px
  font-weight: 400
  line-height: 20px
  color: $neutral-deep-black
.complexInputBlockRO .complexInputBlock__input
  width: 100%

.complexInputBlock__removeBtn
  composes: button
  display: block
  width: 24px
  height: 24px
  flex-shrink: 0
  background-color: transparent
  padding: 0
  cursor: pointer
  svg
    path
      fill: $neutral-dark-gray
      transition: fill .27s ease
  &:hover
    svg path
      fill: $neutral-deep-black

.complexInputBlockRO .complexInputBlock__removeBtn
  display: none

.complexInputBlock__addBtn
  composes: button
  display: block
  width: 24px
  height: 24px
  flex-shrink: 0
  background-color: transparent
  cursor: pointer
  padding: 0
  margin-top: 9px
  svg polygon
    fill: $brand-green-default
    transition: fill .27s ease
  &:hover
    svg polygon
      fill: $brand-purple-default
  +breakpoint(767)
    align-items: flex-start

.complexInputBlockRO .complexInputBlock__addBtn
  display: none

.addDataBtn
  composes: button
  display: flex
  flex-shrink: 0
  align-items: center
  justify-content: center
  width: 40px
  height: 40px
  margin: 0
  padding: 0
  background: $brand-green-lightest
  border: 1px solid $brand-green-lighter
  border-radius: 8px
  transition: background .27s ease, color .27s ease, border .27s ease
  cursor: pointer
  svg polygon
    transition: fill .27s ease
    fill: $brand-green-default
  &_active
    border-radius: 8px
    background: $brand-purple-lightest
    border: 1px solid $brand-purple-lighter
    color: $brand-purple-default
    svg polygon
      fill: $brand-purple-default
  &:hover,
  &:focus,
  &:active
    border: 1px solid $brand-purple-lighter
    border-radius: 8px
    background: $brand-purple-lightest
    color: $brand-purple-default
    svg polygon
      fill: $brand-purple-default
  &_phone,
  &_multiField
    margin-bottom: 15px

// График работы
.workingSchedulesBlock
  padding-top: 16px
  padding-bottom: 16px

.workingHours,
.specialSchedule
  display: block
  position: relative
  padding: 0 16px

.specialSchedule
  border-top: 1px solid #EFF2F1
  margin-top: 19px

// open date
.openingDateWrap
  margin-top: 5px

.openingDate__title
  font-size: 14px
  margin-bottom: 3px

.openDateFlex
  display: flex
  flex-direction: row
  flex-wrap: wrap
  flex-flow: row wrap
  align-items: flex-start
  align-content: flex-start
  justify-content: flex-start
  width: 100%
  max-width: 316px

.openDateSelect
  &_day
    min-width: 80px !important
    margin-right: 12px !important
  &_month
    min-width: 120px !important
    margin-right: 12px !important
  &_year
    min-width: 80px !important
  +breakpoint(767)
    &_day
      min-width: 45px !important
      margin-right: 8px !important
    &_month
      min-width: 110px !important
      margin-right: 8px !important
    &_year
      min-width: 50px !important
  +breakpoint(350)
    &_day
      min-width: 45px !important
      margin-right: 8px !important
    &_month
      min-width: 90px !important
      margin-right: 8px !important
    &_year
      min-width: 45px !important

.openDateSelect__error
  border-color: $status-danger-default

.phoneContainer
  position: relative
  width: 100%
  flex-basis: 100%

.openDateContainer
  position: relative
  width: 60%
  flex-basis: 60%
  +breakpoint(767)
    width: 100%
    flex-basis: 100%
    margin: 0 0 12px

.branchPageSectionPhotos
  margin-bottom: 0

.phoneProvider
  padding-left: 0
  padding-right: 8px
  display: flex
  height: auto
  min-height: 40px
  &:nth-child(1)
    align-items: flex-start
  &__selectWrapper
    display: flex
    flex-wrap: wrap
    height: auto
    min-height: 40px
    align-items: center
  &:hover
    border-color: #e0e9e5
  &__select
    background: $neutral-white
    box-shadow: 0 12px 24px 0 rgba(176,188,182,0.44)
    border-radius: 8px
    position: absolute
    top: 0
    left: calc(100% + 12px)
    display: flex
    flex-direction: column
    z-index: 2
    +breakpoint(500)
      left: auto
      right: 35px

.phoneProviderSelect__item
  font-size: 14px
  color: $neutral-deep-black
  letter-spacing: 0
  line-height: 20px
  cursor: pointer
  padding: 13px 16px
  border: 0
  background: transparent
  text-align: left
  white-space: nowrap
  &:hover
    color: $brand-purple-default

.phoneProviderSelect__selectedItem
  background: rgba(60, 145, 239, 0.12)
  border-radius: 4px
  border: 1px solid rgba(0, 0, 0, 0.02)
  padding: 5px 10px 5px 12px
  color: $status-info-default
  font-weight: 500
  margin: 3px 4px 3px 3px
  font-size: 14px
  letter-spacing: 0
  line-height: 20px

.removeProvider
  cursor: pointer
  margin-left: 8px
  opacity: 0.5

.providersPlaceholder
  font-size: 14px
  color: $neutral-dark-gray
  letter-spacing: 0
  line-height: 20px
  margin-left: 16px
  user-select: none

.phoneRowWithHint
  display: flex
  flex-wrap: wrap
  align-items: center

.phoneOuterRow
  margin: 0
  width: 100%
  flex-basis: 100%

.branchDataHintPhone
  display: inline-block
  min-height: auto

.phoneHintContainer
  display: block

.openDateOuter
  display: flex
  flex-wrap: wrap
  align-items: center

.openDateHintContainer
  display: block

.openDateHintPhone
  display: inline-block
  min-height: auto
  margin-top: 0

// Кнопка-линк на все отзывы филиала
.branchReviewsLink
  display: inline-flex
  width: auto
  align-items: center
  height: 40px
  margin: 0 0 31px
  padding: 0 16px
  border-radius: 8px
  background-color: rgba(42,198,130,0.12)
  font-size: 12px
  font-weight: bold
  color: #2AC682
  letter-spacing: 1px
  text-align: left
  text-transform: uppercase
  transition: color .27s ease, background-color .27s ease
  text-decoration: none
  & span
    display: inline-block
    margin: 0 0 0 4px
    font-weight: 400
  & svg
    display: block
    width: 18px
    margin: 0 10px 0 0
    & *
      fill: #2AC682
      transition: fill .27s ease
  &:hover
    background-color: rgba(125,29,228,0.1)
    color: $brand-purple-default
    text-decoration: none
    & svg *
      fill: $brand-purple-default
  +breakpoint(359)
    padding: 0 10px
    font-size: 11px

// Блок "Не хватает информации"
.missingInfoFlex
  display: flex
  flex-direction: row
  flex-wrap: wrap
  flex-flow: row wrap
  align-items: flex-start
  align-content: flex-start
  justify-content: flex-start
  width: 100%
  margin-bottom: -12px

.missingInfoItem
  display: inline-block
  width: auto
  margin: 0 12px 12px 0
  padding: 9px 16px 11px
  font-size: 14px
  font-weight: 500
  line-height: 20px
  color: $status-danger-default
  text-align: center
  background-color: rgba(255,84,84,0.12)
  border-radius: 8px

// Обновленные часы работы
.workingHoursNewContainer
  display: block
  margin-bottom: 19px
  border-bottom: 1px solid #EFF2F1

.workingHoursNewBlock
  display: block

.workingHoursNewBlock__controlsRow
  display: flex
  flex-direction: row
  flex-wrap: wrap
  flex-flow: row wrap
  align-items: center
  align-content: center
  justify-content: flex-start
  margin: 0 0 13px
  +breakpoint(767)
    margin: 0

.whWeek
  display: flex
  flex-direction: row
  flex-wrap: wrap
  flex-flow: row wrap
  align-items: flex-start
  align-content: flex-start
  justify-content: flex-start
  margin: 0 32px 10px 0
  border: 1px solid $neutral-light-gray-2
  border-radius: 8px
  overflow: hidden
  +breakpoint(767)
    margin: 0 0 24px
    order: 2

.whWeek__input
  display: none

.whWeek__label
  display: block
  width: 56px
  height: 38px
  border-right: 1px solid $neutral-light-gray-2
  font-size: 12px
  line-height: 38px
  font-weight: bold
  text-align: center
  text-transform: uppercase
  letter-spacing: 1px
  color: $neutral-deep-black
  background-color: transparent
  transition: color .27s ease
  cursor: pointer
  &:hover
    color: $brand-purple-default
  &_disabled
    cursor: default
    color: $neutral-medium-gray
    &:hover
      color: $neutral-medium-gray
  &:last-child
    border: 0
  +breakpoint(767)
    width: 37px
    height: 38px

.whWeek__input:checked + .whWeek__label
  color: $brand-purple-default
  background-color: rgba(125,29,228,0.08)

.whWeek__input:checked + .whWeek__label_disabled
  background-color: $neutral-light-gray-2
  color: $neutral-medium-gray
.quickDaysSelectors__wrap
  +breakpoint(767)
    width: 90%
.quickDaysSelectors
  display: flex
  flex-direction: row
  flex-wrap: wrap
  flex-flow: row wrap
  align-items: center
  align-content: center
  justify-content: flex-start
  +breakpoint(767)
    order: 3
    margin: 0 0 24px

.quickDaysSelectors__subtitle
  display: block
  font-style: normal
  font-weight: normal
  font-size: 12px
  line-height: 16px
  color: $neutral-dark-gray

.quickDaysSelectors__btn
  composes: button
  margin: 0 24px 0 0
  padding: 0
  font-size: 12px
  line-height: 23px
  font-weight: bold
  text-align: center
  text-transform: uppercase
  letter-spacing: 1px
  color: #2AC682
  cursor: pointer
  background-color: transparent
  transition: color .27s ease
  &:hover
    color: $brand-purple-default
  &:last-child
    margin: 0
  &_disabled
    color: $neutral-dark-gray
    cursor: auto
    pointer-events: none
    .quickDaysSelectors__btnLeftIcon_svgWrap
      background-color: $neutral-light-gray-2
      border: 1px solid $neutral-light-gray-2
      cursor: auto
      pointer-events: none
      & svg *
        fill: $neutral-medium-gray
  +breakpoint(767)
    font-size: 11px
    margin: 0 10px 0 0

.quickDaysSelectors__btnLeftIcon
  display: flex
  flex-direction: row
  align-items: center
  &:hover .quickDaysSelectors__btnLeftIcon_svgWrap
    border-radius: 8px
    background: $brand-purple-lightest
    border: 1px solid $brand-purple-lighter
    svg polygon
      fill: $brand-purple-default
  &_svgWrap
    display: flex
    align-items: center
    justify-content: center
    margin-right: 12px
    background: $brand-green-lightest
    border-radius: 8px
    composes: button
    width: 40px
    height: 40px
    padding: 5px
    cursor: pointer
    border: 1px solid $brand-green-lighter
    transition: border .27s ease, background .27s ease
    svg polygon
      transition: fill .27s ease
      fill: $brand-green-default

.workingHoursNewBlock__timeRow
  display: flex
  flex-direction: row
  flex-wrap: wrap
  flex-flow: row wrap
  align-items: flex-start
  align-content: flex-start
  justify-content: flex-start
  margin: 0 0 20px

.workingHoursNewBlock__orgmodRow
  margin: 0 0 20px

.whTimeBlock
  display: block
  width: 168px
  margin: 0 32px 0 0
  +breakpoint(767)
    margin: 0 30px 24px 0

.whTimeBlock_break
  width: 200px
  margin: 0

.whTimeBlock__title
  display: block
  margin: 0 0 7px
  font-size: 14px
  line-height: 20px
  font-weight: normal
  text-align: left
  color: #0E0E0E

.whTimeBlock__inputsFlex
  display: flex
  flex-direction: row
  flex-wrap: wrap
  flex-flow: row wrap
  align-items: center
  align-content: center
  justify-content: flex-start
  margin: 0 0 8px

.whTimeBlock__timeInput
  display: block
  width: 72px
  height: 40px
  background-color: transparent
  border: 1px solid $neutral-light-gray-2
  border-radius: 8px
  font-size: 14px
  line-height: 20px
  font-weight: normal
  text-align: center
  color: #0E0E0E
  &:disabled
    background-color: $neutral-light-gray-2
    border: 1px solid $neutral-light-gray-2
    color: $neutral-medium-gray
    cursor: auto
    pointer-events: none

.whTimeBlock__sep
  display: block
  width: 24px
  font-size: 14px
  line-height: 20px
  font-weight: normal
  text-align: center
  color: #0E0E0E

.addBreakBtn
  background: $brand-green-lightest
  border-radius: 8px
  composes: button
  display: flex
  align-items: center
  justify-content: center
  width: 40px
  height: 40px
  margin: 0 0 0 0
  padding: 5px
  cursor: pointer
  transition: color .27s ease, background .27s ease, border .27s ease
  border: 1px solid $brand-green-lighter
  svg polygon
    fill: $brand-green-default
    transition: fill .27s ease
  &:focus,
  &:active
    border-radius: 8px
    border: 1px solid $brand-green-lighter
  &:hover
    border-radius: 8px
    background: $brand-purple-lightest
    border: 1px solid $brand-purple-lighter
    svg polygon
      fill: $brand-purple-default
  &_disabled
    background-color: $neutral-light-gray-2
    border: 1px solid $neutral-light-gray-2
    cursor: auto
    pointer-events: none
    & svg *
      fill: $neutral-medium-gray

.whRemoveBtn
  composes: button
  display: block
  width: 24px
  height: 24px
  margin: 0 0 0 8px
  border: 0
  background-color: transparent
  cursor: pointer
  padding: 0
  & svg path
    fill: $neutral-dark-gray
    transition: fill .27s ease
  &:hover
    & svg path
      fill: $status-danger-default
  &_disabled
    cursor: auto
    pointer-events: none
    opacity: 0.5

.workingHoursNewBlock__controlsRow .whRemoveBtn
  margin: 0 0 0 auto
  +breakpoint(767)
    margin: 0 0 24px auto
    order: 1

.workingHoursNewBlock__controlsRow
  .whRemoveBtn
    +breakpoint(767)
      margin: 0 0 15px auto

.whTimeBlock__checkbox
  display: block
  margin: 11px 0 0

.branchPage__checkboxInput
  display: none

.branchPage__checkboxLabel
  display: inline-block
  margin: 0
  padding: 0 0 0 24px
  font-size: 14px
  line-height: 20px
  font-weight: normal
  text-align: left
  color: #0E0E0E
  position: relative
  cursor: pointer
  &::before
    content: ''
    display: block
    width: 16px
    height: 16px
    border: 2px solid #CCD2CF
    border-radius: 4px
    position: absolute
    left: 0
    top: calc(50% - 8px)

.branchPage__checkboxInput:disabled + .branchPage__checkboxLabel
  opacity: 0.5

.branchPage__checkIcon
  background-color: $brand-purple-default
  border-color: $brand-purple-default
  width: 16px
  border-radius: 4px
  height: auto
  position: absolute
  top: 2px
  left: 0
  opacity: 0
  transition: opacity .27s ease
  path
    stroke: white
    stroke-width: 3px
  &_active
    opacity: 1

.whOrgmode
  display: flex
  margin-bottom: 15px
  padding-bottom: 15px
  border-bottom: 1px solid #EFF2F1
  &__wrapCheckbox
    width: 60%
    +breakpoint(1023)
      width: 100%
  &__hint
    max-width: 264px
    font-size: 12px
    font-weight: normal
    line-height: 16px
    color: $neutral-dark-gray
    +breakpoint(1023)
      display: none

.whComment__wrap
  display: block
  margin-bottom: 15px
  padding-bottom: 19px
  border-bottom: 1px solid #EFF2F1

.whComment
  display: block
  width: 100%
  max-width: 392px
  height: 40px
  margin: 0
  padding: 9px 16px 11px
  border: 1px solid $neutral-light-gray-2
  border-radius: 8px
  font-size: 14px
  line-height: 20px
  font-weight: normal
  text-align: left
  color: $neutral-deep-black
  transition: border-color .27s ease
  &:hover,
  &:focus,
  &:active
    border-color: #2AC682
  &:disabled
    &:hover,
    &:focus,
    &:active
      border-color: $neutral-light-gray-2
  &::placeholder
    color: $neutral-dark-gray

// БЛОК С АДРЕСОМ
.addressBlockFlex
  display: flex
  flex-direction: row
  flex-wrap: nowrap
  flex-flow: row nowrap
  align-content: flex-start
  align-items: flex-start
  justify-content: flex-start
  width: 100%
  +breakpoint(767)
    display: block

.addressBlockFields
  display: block
  width: auto
  max-width: 392px
  flex-basis: 50%

.addressBlockMapContainer
  display: block
  width: 100%
  height: 416px
  flex-basis: auto
  max-width: 336px
  margin: 27px 0 0 48px
  padding: 0
  border: 1px solid #EBF0EE
  box-shadow: 0px 2px 4px rgba(177, 199, 188, 0.12)
  border-radius: 8px
  position: relative
  background-color: #e0e0e0
  overflow: hidden
  +breakpoint(767)
    margin: 24px 0 0

.addressBlockMapContainerInner
  position: absolute
  left: 0
  top: 0
  height: 100%
  width: 100%
  z-index: 1

.addressBlockMap__cancelBtn
  appearance: none
  display: block
  width: 97px
  height: 36px
  border: 0
  border-radius: 6px
  background-color: #ffffff
  box-shadow: 0px 1px 2px rgba(14,14,14,0.2)
  font-size: 12px
  line-height: 36px
  font-weight: 700
  letter-spacing: 1px
  text-align: center
  text-transform: uppercase
  color: #838A88
  position: absolute
  top: -40px
  left: 12px
  cursor: pointer
  z-index: 2
  transition: color .27s ease
  &:hover,
  &:focus,
  &:active
    border: 0
    border-radius: 6px
    box-shadow: 0px 1px 2px rgba(14,14,14,0.2)
    background-color: #ffffff
  &:hover
    color: $status-danger-default

.addressBlockMap__cancelBtn__active
  transform: translateY(52px)

.addressBlockMapZoomControls
  display: block
  width: 36px
  height: 77px
  position: absolute
  top: 12px
  right: 12px
  z-index: 2

.addressBlockMapZoomControls__plus
  display: block
  width: 36px
  height: 36px
  margin: 0 0 5px
  padding: 10px
  border-radius: 50%
  border: 0
  background-color: #ffffff
  box-shadow: 0px 1px 2px rgba(14, 14, 14, 0.2)
  cursor: pointer
  & svg
    display: block
    width: 16px
    height: 16px
  & svg *
    fill: #646A68
    transition: fill .27s ease
  &:hover
    & svg *
      fill: #2AC682
.addressBlockMapZoomControls__minus
  display: block
  width: 36px
  height: 36px
  margin: 0
  padding: 17px 10px
  border-radius: 50%
  border: 0
  background-color: #ffffff
  box-shadow: 0px 1px 2px rgba(14, 14, 14, 0.2)
  cursor: pointer
  & svg
    display: block
    width: 16px
    height: 2px
  & svg *
    fill: #646A68
    transition: fill .27s ease
  &:hover
    & svg *
      fill: #2AC682

.addressBlockMapCoordinates
  display: flex
  flex-direction: row
  flex-wrap: nowrap
  flex-flow: row nowrap
  align-content: flex-start
  align-items: flex-start
  justify-content: space-between
  width: calc(100% - 24px)
  margin: 0 auto
  padding: 6px 12px 11px
  position: absolute
  bottom: 12px
  left: 0
  right: 0
  background-color: #fff
  border-radius: 8px
  box-shadow: 0px 1px 2px rgba(14, 14, 14, 0.2)
  z-index: 2

.addressBlockMapCoordinates__lng
  display: block
  width: calc(50% - 12px)
  flex-basis: calc(50% - 12px)

.addressBlockMapCoordinates__lat
  display: block
  width: calc(50% - 12px)
  flex-basis: calc(50% - 12px)

.addressBlockMapCoordinates__title
  display: block
  width: 100%
  height: 12px
  font-size: 12px
  line-height: 12px
  font-weight: 400
  color: #838A88
  text-align: left

.addressBlockMapCoordinates__value
  display: block
  width: 100%
  height: 20px
  border: none
  border-bottom: 1px solid $neutral-light-gray-2
  font-size: 14px
  line-height: 20px
  font-weight: 400
  color: $neutral-deep-black
  text-align: left

// Блок с общим на блок хинтом
.sectionHintWrapper
  display: flex
  +breakpoint(767)
    flex-direction: column
  &__fieldsContainer
    flex-basis: 60%
    +breakpoint(767)
      width: 100%
  &__hintContainer
    +breakpoint(767)
      margin-top: 15px
  &__hintText
    font-size: 12px
    line-height: 16px
    color: $neutral-dark-gray
    width: 200px
    +breakpoint(767)
      width: 100%

.circleIcons
  display: flex
  padding-top: 40px
  padding-bottom: 3px
  +breakpoint(767)
    padding-top: 0

.circleIcon
  width: 20px
  border-radius: 50%
  height: 20px
  border: 1px solid rgba(22, 22, 22, 0.02)
  display: flex
  padding: 1px
  margin-right: 4px
  justify-content: center
  align-items: center
  &_yandex
    background: rgba(252, 63, 29, 0.12)
  &_2gis
    background: rgba(47, 173, 0, 0.12)

.sectionHintWrapper
  .branchDataInputContainer
    flex-basis: 100%
    width: 100%
    .branchDataInputText
      max-width: 100%
