@import 'Styles/utils.sass'

.footer
  display: flex
  justify-content: flex-start
  padding: 20px
  +breakpoint(767)
    flex-direction: column
    line-height: 1.5
  .credits
    .name
      font-weight: 500
    .description
      margin: 0 20px
      +breakpoint(767)
        display: block
        margin-left: 0

.footer_auth
  bottom: 0
  left: 0
  width: 100%
  position: fixed
  +breakpoint(767)
    position: static
