$background-disable: #f3f7fa
$text-field-and-rulers: #e2e9ee

$neutral-deep-black: #202526
$neutral-light-black: #394046
$neutral-deep-gray: #5C6A75
$neutral-dark-gray: #8A99A4
$neutral-medium-gray: #BDC9D3
$neutral-light-gray-1: #D2D9DF
$neutral-light-gray-2: #E2E9EE
$neutral-lightest-gray: #F3F7FA
$neutral-white: #FFFFFF

$status-info-darker: #2070ca // нет в дизайн системе
$status-info-default: #3C91EF
$status-info-lighter: #D0E5FB
$status-info-lightest: #E8F2FD
$status-info-super-lightest: #F6FAFE

$status-green-darker: #0aa46e // нет в дизайн системе
$brand-green-default: #17c788
$brand-green-lighter: #C7F2E2
$brand-green-lightest: #E3F8F1

$brand-purple-default: #7d1de4
$brand-purple-lighter: #E0C9F9
$brand-purple-lightest: #EFE3FB

$status-danger-darker:  #C73232
$status-danger-default:  #E55050
$status-danger-lighter: #F9D5D5
$status-danger-lightest: #FCEAEA

$status-warning-lightest: #FFEEE0
