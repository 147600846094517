@import 'Styles/commonVariables.sass'
@import 'Styles/utils.sass'

=mobile-link-item
  align-items: center
  color: inherit
  display: inline-flex
  font-weight: 500
  overflow: hidden
  position: relative
  width: 100%
  &:hover,
  &:focus,
  &.isActive
    color: $brand-purple-default
    text-decoration: none
    &::before
      background-color: $brand-purple-default
      bottom: 51%
      content: ''
      left: 0
      position: absolute
      top: 51%
      transition: top .27s ease, bottom .27s ease
      width: 3px
    &:hover,
    &:focus,
    &.isActive
      &::before
        bottom: 8px
        top: 8px

.siteNav
  display: flex
  flex: 1
  flex-shrink: 0
  padding: 0 10px 0 0
  background: $neutral-white
  +breakpoint(767)
    padding: 0 10px
    flex: none
  .burger
    cursor: pointer
    display: none
    font-size: 0
    width: 25px
    svg
      width: 25px
    +breakpoint(767)
      align-items: center
      display: flex
      justify-content: center

.mobileNav
  +make-pseudo-important
    .wrapLink
      &_isCounter
        display: flex
        padding-right: 10px
        align-items: center
        .link
          width: auto !important
    .counterMessages
      background: $neutral-lightest-gray
      border: 1px solid $neutral-light-gray-2
      box-sizing: border-box
      border-radius: 12px
      padding: 1px 7px
      line-height: 16px
      span
        font-style: normal
        font-weight: normal
        font-size: 12px
        text-align: center
        color: $neutral-dark-gray
        line-height: 16px
    +breakpoint(767)
      display: flex
      flex-direction: column
      height: 100%
      min-height: 100%
      padding: 12px 0
      width: 200px
      .mainList
        flex: 1
        .link
          font-size: 18px
          line-height: 24px
          padding: 12px 12px 12px 24px
          +mobile-link-item
        .link_disabled
          &:hover,
          &:focus
            color: inherit
          &::before
            display: none

.mobileFooter
  .link
    padding: 12px 12px 12px 20px
    +mobile-link-item
    font-size: 14px
    line-height: 20px
    color: $neutral-deep-black

    &:hover,
    &:focus,
    &.isActive
      .icon
        *
          fill: $brand-purple-default
    .icon
      font-size: 0
      width: 24px
      height: 24px
      margin-right: 12px
      display: flex
      align-items: center
      justify-content: center

      *
        fill: $neutral-deep-black
      svg
        width: 24px

.submenuMobile
  margin-left: 24px
  margin-right: 24px
  margin-bottom: 10px
  border-bottom: 1px solid $text-field-and-rulers

.submenuMobileItem
  display: block
  margin-bottom: 16px
  font-size: 16px
  line-height: 24px
  color: $neutral-deep-black
  transition: color .27s ease
  text-decoration: none

.submenuMobileItem__active
  color: $brand-purple-default

.iconsNav
  display: flex
  +breakpoint(767)
    display: none

.iconLink
  width: 56px
  align-items: center
  border-right: 1px solid #e0e9e5
  display: flex
  justify-content: center
  position: relative
  .icon
    width: 24px
    height: 24px
  &:hover,
  &:focus,
  &.isActive
    color: $brand-purple-default
    text-decoration: none
    &::before
      left: 0
      right: 0
    svg path
      fill: $brand-purple-default
  &::before
    margin: 0 10px
    background-color: $brand-purple-default
    bottom: 0
    content: ''
    height: 2px
    left: 51%
    position: absolute
    right: 51%
    transition: left .27s ease, right .27s ease
