@import 'Styles/commonVariables.sass'
@import 'Styles/utils.sass'

.button
  appearance: none
  outline: none
  border: 0
  border-radius: 0
  box-shadow: none
  &:hover,
  &:focus,
  &:active
    outline: none
    border: 0
    border-radius: 0
    box-shadow: none

.notification
  display: block
  max-width: 380px
  background-color: transparent
  &__title
    display: block
    margin: 0 0 4px
    font-size: 12px
    font-weight: 600
    line-height: 20px
    color: #ffffff
    text-align: left
    text-transform: uppercase
    letter-spacing: 1px
    &_withCounter
      display: flex
      align-items: center
    &_withoutTitle
      margin: 0
  &__counter
    background: $neutral-dark-gray
    color: $neutral-medium-gray
    border: 1px solid $neutral-dark-gray
    border-radius: 12px
    margin-left: 7px
    justify-content: center
    display: flex
    align-items: center
    min-width: 28px
  &__markAllReadButton
    composes: button
    background: #FFF
    color: $neutral-deep-gray
    border-radius: 8px
    justify-content: center
    display: flex
    align-items: center
    font-weight: bold
    font-size: 12px
    line-height: 16px
    letter-spacing: 1px
    text-transform: uppercase
    padding: 8px 9px
    margin-top: 11px
    cursor: pointer
    transition: background .27s ease
    &:hover
      background: $neutral-medium-gray
      border-radius: 8px
  &__text
    display: block
    margin: 0
    font-size: 14px
    font-weight: 400
    line-height: 20px
    color: #ffffff
    text-align: left
    word-wrap: break-word
