@import 'Styles/utils.sass'

.headerLogo
  &__pointerLogoWrap
    svg
      width: 16px
      height: 24px
  &__crossIconWrap
    margin-left: 10px
    width: 10px
    margin-right: 10px
    display: flex
    align-items: center
  &__crossIcon
    width: 11px
  &__lemmaIcon
    margin-bottom: 10px
  &__wrapWhitelabel
    display: flex
    &_strategy
      height: 100%
      svg
        height: auto
        width: 40px
    &_ingate
      height: 100%
      svg
        height: auto
        width: 49px
    &_lemma
      height: 100%
      svg
        height: auto
        width: 82px
