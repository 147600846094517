@font-face
  font-family: 'Gilroy'
  src: url('../fonts/Gilroy-ExtraBold.woff') format('woff')
  font-weight: bold
  font-style: normal
  font-display: swap

@font-face
  font-family: 'Gilroy'
  src: url('../fonts/Gilroy-Light.woff') format('woff')
  font-weight: 300
  font-style: normal
  font-display: swap
