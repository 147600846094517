*
  outline: 0

body
  background-color: #fff
  color: #0e0e0e
  font-family: 'IBM Plex Sans', sans-serif
  font-size: 14px
  font-smoothing: antialiased
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  text-rendering: optimizeLegibility
  font-feature-settings: 'liga', 'kern'
  -webkit-font-feature-settings: 'liga', 'kern'
  -moz-font-feature-settings: 'liga', 'kern'
  line-height: 1.43

body.ReactModal__Body--open
  overflow: hidden

img
  max-width: 100%

a
  color: #2ac682
  text-decoration: none
  transition: color .27s ease
  &:hover
    color: #0b9b5c
    text-decoration: underline

.dynamicsGraphContainer
  svg
    path
      opacity: 0.48

.svg-rect-transition
  transition: fill 0.27s ease

// Базовый z-index у тостеров 19999. Перезаписал базовый z-index у виджета helpdeskeddy для того,
// чтобы тостеры отображались поверх виджета и их можно было закрывать
#hde-container
  z-index: 19998 !important
