@import 'Styles/utils.sass'

.headerLogo
  &__pointerLogoWrap
    svg
      width: 16px
      height: 24px
    &_NY
      svg
        height: auto
        width: auto
