@import 'Styles/utils.sass'

.scrim
  display: none
  +breakpoint(767)
    background-color: rgba(14, 14, 14, .7)
    bottom: 0
    display: block
    height: 100%
    position: fixed
    inset: 0
    width: 100%
    &.isOpen
      opacity: 0
      transition: opacity .4s ease, z-index 0s linear .6s
      pointer-events: none
    &.isClose
      opacity: 1
      transition: opacity .6s ease

.side
  display: none
  +breakpoint(767)
    background-color: #fff
    display: block
    position: fixed
    left: 0
    overflow-y: auto
    -webkit-overflow-scrolling: touch
    &.isOpen
      transform: translateX(-100%) scaleX(.8)
      transition: transform .4s ease, z-index 0s linear .6s
    &.isClose
      transform: translateX(0) scaleX(1)
      transition: transform .6s ease

.removeScrollContainer
  min-height: 100%
  height: 100%
  +breakpoint(767)
    overflow-y: auto
