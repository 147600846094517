=breakpoint($point, $type: 'max-width', $unit: 'px')
  @media ($type: $point + $unit)
    @content

=breakpointMinMax($pointMin, $pointMax)
  @media ('min-width': $pointMin + 'px') and ('max-width': $pointMax + 'px')
    @content

=make-pseudo-important
  &#{&}
    @content
