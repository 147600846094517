@import 'Styles/utils.sass'

.authLogo
  &__pointerLogoWrap
    height: 37px
  &__crossIconWrap
    margin-left: 10px
    width: 10px
    margin-right: 10px
    display: flex
    align-items: center
  &__crossIcon
    width: 11px
  &__lemmaIcon
    margin-bottom: 10px
  &__wrapWhitelabel
    display: flex
    &_strategy
      width: 100%
      height: 100%
    &_ingate
      width: 100%
      height: 100%
      svg
        height: auto
        width: 60px
    &_lemma
      height: 100%
      svg
        height: auto
        width: 82px
