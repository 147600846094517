@import 'Styles/commonVariables.sass'
@import 'Styles/utils.sass'

.mainNav
  display: flex
  padding: 0
  margin: 0
  display: flex
  width: 100%
  position: relative
  +breakpoint(767)
    display: none
  .list
    display: flex
    flex-wrap: wrap
    padding-left: 10px
    flex-direction: row
    list-style: none
    white-space: nowrap
    width: 100%
    position: relative
    padding: 0
    margin: 0
    flex-wrap: wrap
  .wrapLink
    padding: 0
    &_isCounter
      display: flex
      padding-right: 5px
      align-items: center
  .link
    align-items: center
    color: inherit
    display: inline-flex
    font-weight: 500
    height: 100%
    justify-content: center
    overflow: hidden
    margin: 0 10px
    position: relative
    transition: background .27s ease, color .27s ease
    cursor: pointer
    user-select: none
    &.isEnable
      color: $neutral-dark-gray
    &:hover,
    &:focus,
    &.isActive
      color: $brand-purple-default
      text-decoration: none
      &::before
        left: 0
        right: 0
    &::before
      background-color: $brand-purple-default
      bottom: 0
      content: ''
      height: 2px
      left: 51%
      position: absolute
      right: 51%
      transition: left .27s ease, right .27s ease
  .counterMessages
    background: $neutral-lightest-gray
    border: 1px solid $neutral-light-gray-2
    box-sizing: border-box
    border-radius: 12px
    padding: 1px 7px
    line-height: 16px
    span
      font-style: normal
      font-weight: normal
      font-size: 12px
      text-align: center
      color: $neutral-dark-gray
      line-height: 16px

.moreButtonWrap
  position: relative

.moreListPopup
  background: $neutral-white
  box-shadow: 0 12px 24px $neutral-light-gray-1
  border-radius: 0 0 12px 12px
  margin-top: 1px
  padding: 0
  text-align: left
  position: absolute
  min-width: 216px
  z-index: -1
  transition: transform .27s ease
  bottom: 55px
  &_open
    transform: translateY(calc(100% + 56px))
  .submenuItem
    &:last-child
      transition: .27s ease
      border-bottom: 1px solid $neutral-light-gray-1
      &:hover
        border-bottom: 1px solid rgba(125, 29, 228, 0.24)
  +breakpoint(991)
    right: 0
  .link
    display: block
    padding: 9px 16px
    font-weight: 400
    margin: 0
    &::before
      display: none
    &:hover
      background: rgba(125, 29, 228, 0.08)
  .wrapLink
    &:not(:last-child)
      .link
        border-bottom: 1px solid $neutral-light-gray-1
    &:last-child
      .link
        border-radius: 0 0 12px 12px

.submenu
  overflow: hidden
  display: flex
  flex-direction: column
  box-shadow: 0 12px 24px $neutral-light-gray-1
  border-radius: 0 0 12px 12px
  position: absolute
  min-width: 200px
  max-width: 300px
  background-color: #fff
  z-index: -1
  bottom: 55px
  transition: transform .27s ease

.submenuItem
  font-size: 14px
  line-height: 20px
  color: $neutral-deep-black
  transition: color .27s ease, background .27s ease
  padding: 9px 16px 9px 16px
  &:not(:last-child)
    border-bottom: 1px solid $neutral-light-gray-1
  &:hover
    background: rgba(125, 29, 228, 0.08)
    color: $brand-purple-default
    text-decoration: none
    &:not(:last-child)
      border-bottom: 1px solid rgba(125, 29, 228, 0.24)
  &_active
    color: $brand-purple-default

.submenu__open
  transform: translateY(calc(100% + 56px))

.moreButtonWrap
  .submenu
    position: relative
    box-shadow: none
    border-radius: 0
    min-width: initial
    max-width: initial
    background-color: initial
    z-index: initial
    bottom: initial

  .submenuItem
    font-size: 14px
    line-height: 20px
    color: $neutral-deep-black
    padding: 9px 16px 9px 35px
    &:not(:last-child)
      border-bottom: 1px solid $neutral-light-gray-1
    &:hover
      background: rgba(125, 29, 228, 0.08)
      color: $brand-purple-default
      text-decoration: none
      &:not(:last-child)
        border-bottom: 1px solid rgba(125, 29, 228, 0.24)
    &_active
      color: $brand-purple-default
