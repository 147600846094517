@import 'Styles/utils.sass'

.headerLogo
  align-items: center
  display: flex
  font-size: 0
  height: 56px
  justify-content: center
  transition: opacity .27s ease
  padding-left: 20px
  padding-right: 20px
  flex-shrink: 0
  &_NY
    padding-left: 12px
    padding-right: 12px
  &_strategy
    padding-right: 13px
  +breakpoint(767)
    height: 48px
    padding-left: 16px
    padding-right: 16px
    &_strategy
      padding-right: 12px
  &:hover
    opacity: .7
