@import 'Styles/utils.sass'

.page
  display: flex
  flex-direction: column
  min-height: 100vh

.main
  flex: 1
  &_competitorsMonitoringPage
    display: flex
    flex-direction: column
