@import 'Styles/commonVariables.sass'
@import 'Styles/utils.sass'

.errorPageMessage
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column
  width: auto
  min-width: 361px
  max-width: 456px
  height: auto
  margin: 0
  padding: 0 0 40px
  background-color: transparent
  background-repeat: no-repeat
  background-position: 50% 40px
  background-size: auto 120px
  color: $neutral-dark-gray
  font-size: 20px
  line-height: 24px
  text-align: center
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%,-50%)
  &__containerImage
    display: flex
    justify-content: center
    align-items: center
    background-color: #EEF0F1
    border-radius: 50%
    width: 120px
    height: 120px
    svg path
      fill: $neutral-dark-gray
  &__titleMessage
    padding-top: 20px
    font-size: 22px
    font-weight: 500
  &__404
    padding: 20px 0 40px
    min-width: 358px
    gap: 15px
    svg
      max-width: 245px
  &__500
    padding: 20px 0 40px
    min-width: 358px
    gap: 15px
    svg
      max-width: 245px
  +breakpoint(500, $type: 'max-height')
    position: static
    margin: 0 auto
    transform: none
  +breakpoint(479)
    width: 100%
    min-width: auto
