@import 'Styles/commonVariables.sass'
@import 'Styles/utils.sass'

.head
  cursor: pointer
  font-weight: 500
  position: relative
  transition: opacity .27s ease
  &:hover
    opacity: .7
  &::after
    border-left: 4px solid transparent
    border-right: 4px solid transparent
    border-top: 4px solid $neutral-deep-black
    content: ''
    height: 0
    position: absolute
    right: 15px
    top: 50%
    transform: translateY(-50%)
    transition: border .27s ease
    width: 0
  &.isOpen:not(.disabled)
    &::after
      border-bottom: 4px solid $neutral-deep-black
      border-top: 0
  &.disabled
    cursor: default
    opacity: 0.48
    color: $neutral-dark-gray
    &::after
      border-top: 4px solid $neutral-dark-gray
