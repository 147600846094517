.fullscreen {
  z-index: 3147483000;
  position: relative;
}
.my-masonry-grid {
  display: flex;
  width: auto;
  margin-left: -24px; /* gutter size offset */
}
.my-masonry-grid_column {
  padding-left: 24px; /* gutter size */
  background-clip: padding-box;
}
.my-masonry-grid_column > div {
  margin-bottom: 24px;
}
a.notificationText_btn {
  display: inline-block;
  padding: 6px 14px;
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #FFF;
  border-radius: 8px;
  text-decoration: none;
  background-color: #17C788;
  background-image: none;
  background-repeat: no-repeat;
  background-size: 12px auto;
  background-position: 100% 50%;
  transition: color .27s ease, background-color .27s ease;
  margin: 7px 0 3px;
}
a.notificationText_btn:hover {
  color: #FFF;
  background-color: #7d1de4;
  text-decoration: none;
}
a.notificationText_btn_phantoms {
  background: #FFF;
  color: #5C6A75;
}
a.notificationText_btn_phantoms:hover {
  color: #5C6A75;
  background: #BDC9D3;
  border-radius: 8px;
}

.calendarRangeWrapper .CalendarMonthGrid {
  z-index: 2;
  background: transparent;
}

.CalendarMonth {
  background: transparent;
}
@media (max-width: 768px) {
  .CalendarMonth {
    padding: 0 !important;
  }
}

.DayPicker_weekHeader {
  top: 67px;
}

.DayPicker_transitionContainer__horizontal {
  min-height: 339px;
}

/* Form IO styles */
.formio-component-textarea .col-form-label,
.formio-component-number .col-form-label,
.formio-component-radio .col-form-label {
  display: block;
  padding-bottom: 4px;
  padding-top: 7px;
}

.formio-component-textarea textarea {
  resize: vertical;
  width: 100% !important;
  appearance: none;
  box-shadow: none;
  padding: 7px 12px;
  border-radius: 8px;
  border: 1px solid #e2e9ee;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  transition: border .27s ease;
  color: #202526;
}

.formio-component-textarea textarea:hover {
  border-color: #7d1de4;
}

.formio-component-textarea textarea:hover,
.formio-component-textarea textarea:focus,
.formio-component-textarea textarea:active {
  outline: none;
}

.formio-component-number input {
  width: 100% !important;
  appearance: none;
  box-shadow: none;
  padding: 7px 12px;
  border-radius: 8px;
  border: 1px solid #e2e9ee;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  transition: border .27s ease;
  color: #202526;
}

.formio-component-number .sr-only {
  display: none;
}

.formio-component-number input:hover {
  border-color: #7d1de4;
}

.formio-component-number input:hover,
.formio-component-number input:focus,
.formio-component-number input:active {
  outline: none;
}

.formio-component-radio .form-radio {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  gap: 5px;
  height: 35px;
}

.formio-component-radio .form-check,
.formio-component-radio .form-check-inline {
  display: flex;
  flex-grow: 1;
}

.formio-component-radio .form-check-label {
  display: flex;
  width: 100%;
}

.formio-component-radio .form-check-label {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  border: 0;
  border: 1px solid #C7F2E2;
  border-radius: 8px;
  color: #FFF;
  color: #17c788;
  background-color: #17c788;
  background: #E3F8F1;
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color .27s ease;
}

.formio-component-radio .form-check-label:hover {
  background-color: #7d1de4;
  border: 1px solid rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  background-color: rgba(125, 29, 228, 0.2);
  color: #7d1de4;
}

.formio-component-radio .radio-selected .form-check-label {
  background-color: #7d1de4;
  color: #FFF;
  border: 1px solid #7d1de4
}

.formio-component-radio .form-check-label input {
  display: none;
}

.formio-component-radio .form-text.text-muted {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #8A99A4;
}

.formio-component-columns {
  display: flex;
  margin-top: 28px;
  gap: 10px;
}

.formio-component-button .has-error,
.formio-component-button .has-success {
  display: none
}

.formio-component-button button {
  border-radius: 8px;
  height: 48px;
  padding: 0 19px;
  color: #FFF;
  background: #17c788;
  transition: background 0.27s ease;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}

.formio-component-button button:hover {
  background-color: #7d1de4;
}

.formio-component-skipButton button {
  display: block;
  height: 48px;
  border: 0;
  border-radius: 8px;
  background: #e2e9ee;
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
  color: #5C6A75;
  cursor: pointer;
  transition: color 0.27s ease;
  padding-left: 25px;
  padding-right: 25px;
}

.formio-component-skipButton button:hover {
  color: #7d1de4;
  background: #e2e9ee;
}

.formio-component-wrapper .alert {
  display: none
}
