@import 'Styles/commonVariables.sass'

.notificationCloseBtn
  appearance: none
  width: 20px
  height: 20px
  margin: 0
  padding: 0
  background-color: $neutral-white
  border: 0
  border-radius: 50%
  box-shadow: none
  outline: none
  cursor: pointer
  position: absolute
  top: 8px
  right: 8px
  z-index: 1
  transition: opacity .27s ease
  &:hover,
  &:focus,
  &:active
    border: 0
    border-radius: 50%
    box-shadow: none
    outline: none
    background-color: $neutral-white
    opacity: 0.5
  & svg
    width: 10px
    height: 10px
    path
      fill: $neutral-deep-gray
