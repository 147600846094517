@import 'Styles/commonVariables.sass'
@import 'Styles/utils.sass'

.button
  appearance: none
  outline: none
  border: 0
  border-radius: 0
  box-shadow: none
  &:hover,
  &:focus,
  &:active
    outline: none
    border: 0
    border-radius: 0
    box-shadow: none

.lkNotificationsContainer
  display: block
  width: 100%
  max-width: 436px
  background-color: transparent !important
  margin: 0
  padding: 0

.lkNotificationWrapper
  display: block
  max-width: 436px
  margin: 12px 0 0
  padding: 9px 40px 11px 16px
  border-radius: 12px !important
  box-shadow: none
  position: relative

.lkNotificationsContainerDefault
  background-color: rgba(97, 111, 121, 0.92) !important

.lkNotificationsContainerError
  background-color: rgba(255, 84, 84, 0.92) !important

.lkNotificationsContainerSuccess
  background-color: rgba(42, 198, 130, 0.92) !important

.lkNotificationsContainerWarning
  background-color: rgba(255, 119, 0, 0.92) !important

.lkNotificationsContainerInfo
  background-color: rgba(85, 166, 255, 0.92) !important

.lkNotification
  display: block
  max-width: 380px
  background-color: transparent

.lkNotification__title
  display: block
  margin: 0 0 4px
  font-size: 12px
  font-weight: 600
  line-height: 20px
  color: #ffffff
  text-align: left
  text-transform: uppercase
  letter-spacing: 1px
  &_withCounter
    display: flex
    align-items: center
  &_withoutTitle
    margin: 0

.lkNotification__counter
  background: $neutral-dark-gray
  color: $neutral-medium-gray
  border: 1px solid $neutral-dark-gray
  border-radius: 12px
  margin-left: 7px
  justify-content: center
  display: flex
  align-items: center
  min-width: 28px

.lkNotification__markAllReadButton
  composes: button
  background: #FFF
  color: $neutral-deep-gray
  border-radius: 8px
  justify-content: center
  display: flex
  align-items: center
  font-weight: bold
  font-size: 12px
  line-height: 16px
  letter-spacing: 1px
  text-transform: uppercase
  padding: 8px 9px
  margin-top: 11px
  cursor: pointer
  transition: background .27s ease
  &:hover
    background: $neutral-medium-gray
    border-radius: 8px

.lkNotification__text
  display: block
  margin: 0
  font-size: 14px
  font-weight: 400
  line-height: 20px
  color: #ffffff
  text-align: left
  word-wrap: break-word

// Стиль для ссылок, стилизованных под кнопку, в тексте тостера находится в src/styles/custom.css

.lkNotificationClsBtn
  appearance: none
  width: 20px
  height: 20px
  margin: 0
  padding: 0
  background-color: $neutral-white
  border: 0
  border-radius: 50%
  box-shadow: none
  outline: none
  cursor: pointer
  position: absolute
  top: 8px
  right: 8px
  z-index: 1
  transition: opacity .27s ease
  &:hover,
  &:focus,
  &:active
    border: 0
    border-radius: 50%
    box-shadow: none
    outline: none
    background-color: $neutral-white
    opacity: 0.5
  & svg
    width: 10px
    height: 10px
    path
      fill: $neutral-deep-gray
